<template>
  <v-layout fill-height>
    <v-flex scroll-y>
      <v-data-table
        :headers="headers"
        :items="allModules"
        :loading="loading"
        item-key="id"
        class="elevation-1"
        disable-initial-sort
        :pagination.sync="pagination"
      >
        <template v-slot:headers="props">
          <tr>
            <th v-for="header in props.headers" :key="header.text">{{ header.text }}</th>
          </tr>
        </template>
        <template v-slot:items="props">
          <tr
            :active="props.selected"
            justify-center
            @click="props.selected = !props.selected"
          >
            <td>
              <v-switch
                v-model="props.item.is_active"
                hide-details
                color="primary"
                class="justify-center"
                @change="updateModule(props.item)"
              />
            </td>
            <td>
              {{ props.item.title }}
            </td>
            <td>
              {{ props.item.description }}
            </td>
            <td>
              <v-edit-dialog :return-value.sync="props.item.icon">
                {{ props.item.icon }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.icon"
                    single-line
                    @change="updateModule(props.item)"
                  />
                </template>
              </v-edit-dialog>
            </td>
            <td>
              {{ props.item.name }}
            </td>
            <td>
              <v-switch
                v-model="props.item.hide_if_not_active"
                color="primary"
                hide-details
                class="justify-center"
                @change="updateModule(props.item)"
              />
            </td>
            <td>
              <v-switch
                v-model="props.item.is_administration_module"
                color="primary"
                hide-details
                class="justify-center"
                @change="updateModule(props.item)"
              />
            </td>
            <td>
              <v-switch
                v-model="props.item.is_for_accounting_firms"
                color="primary"
                hide-details
                class="justify-center"
                @change="updateModule(props.item)"
              />
            </td>
            <td>
              <v-switch
                v-model="props.item.is_for_companies"
                color="primary"
                hide-details
                class="justify-center"
                @change="updateModule(props.item)"
              />
            </td>
            <td>
              <v-switch
                v-model="props.item.is_for_guests"
                color="primary"
                hide-details
                class="justify-center"
                @change="updateModule(props.item)"
              />
            </td>
            <td>
              <v-switch
                v-model="props.item.is_home"
                hide-details
                color="primary"
                class="justify-center"
                @change="updateModule(props.item)"
              />
            </td>
            <td>
              <v-switch
                v-model="props.item.is_navbar"
                color="primary"
                hide-details
                class="justify-center"
                @change="updateModule(props.item)"
              />
            </td>
            <td>
              <v-switch
                v-model="props.item.is_settings"
                color="primary"
                hide-details
                class="justify-center"
                @change="updateModule(props.item)"
              />
            </td>
            <td>
              <v-switch
                v-model="props.item.is_webapp"
                color="primary"
                hide-details
                class="justify-center"
                @change="updateModule(props.item)"
              />
            </td>
            <td>
              <v-switch
                v-model="props.item.is_mobile_app"
                color="primary"
                hide-details
                class="justify-center"
                @change="updateModule(props.item)"
              />
            </td>
            <td>
              <v-switch
                v-model="props.item.is_generic_mobile_app"
                color="primary"
                hide-details
                class="justify-center"
                @change="updateModule(props.item)"
              />
            </td>
            <td>
              <v-edit-dialog :return-value.sync="props.item.accounting_firm_url">
                {{ props.item.accounting_firm_url }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.accounting_firm_url"
                    single-line
                    @change="updateModule(props.item)"
                  />
                </template>
              </v-edit-dialog>
            </td>
            <td>
              <v-edit-dialog :return-value.sync="props.item.vendor_url">
                {{ props.item.vendor_url }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.vendor_url"
                    single-line
                    @change="updateModule(props.item)"
                  />
                </template>
              </v-edit-dialog>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import SuperAdminModuleGuard from "@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard";

export default {
  name: "ModuleManager",
  mixins: [SuperAdminModuleGuard],
  data: function () {
    return {
      headers: [
        {
          text: this.$t("sa.activated"),
          align: "center",
        },
        {
          text: this.$t("sa.title"),
          align: "center",
        },
        {
          text: this.$t("sa.description"),
          align: "center",
        },
        {
          text: this.$t("sa.icon"),
          align: "center",
        },
        {
          text: this.$t("sa.name"),
          align: "center",
        },
        {
          text: this.$t("sa.hide_if_not_active"),
          align: "center",
        },
        {
          text: this.$t("sa.admin"),
          align: "center",
        },
        {
          text: this.$t("sa.accounting_firm"),
          align: "center",
        },
        {
          text: this.$t("sa.company"),
          align: "center",
        },
        {
          text: this.$t("sa.guest"),
          align: "center",
        },
        {
          text: this.$t("sa.home"),
          align: "center",
        },
        {
          text: this.$t("sa.navbar"),
          align: "center",
        },
        {
          text: this.$t("sa.settings"),
          align: "center",
        },
        {
          text: this.$t("sa.webapp"),
          align: "center",
        },
        {
          text: this.$t("sa.mobile_app"),
          align: "center",
        },
        {
          text: this.$t("sa.generic_mobile_app"),
          align: "center",
        },
        {
          text: this.$t("sa.accounting_firm_url"),
          align: "center",
        },
        {
          text: this.$t("sa.vendor_url"),
          align: "center",
        },
      ],
      pagination: {
        rowsPerPage: 10,
      },
      allModules: [],
      loading: false,
    };
  },
  created: function () {
    this.loading = true;
    this.service
      .getModules()
      .then((res) => {
        this.allModules = res;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    updateModule: function (item) {
      this.service.updateModule(item.id, item).then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.modules.update'))
				});
    },
  },
};
</script>
