import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

/**
 * @api POST /api/accounting-firms/{accountingFirmId}/vendors/{vendorId}/themes
 *
 * @param {Number} accountingFirmId
 * @param {Number} vendorId
 * @param {Object} data
 */
const create = (accountingFirmId, vendorId, data) => {
	const url = `accounting-firms/${accountingFirmId}/vendors/${vendorId}/themes`
	return axios.post(url, data)
}

/**
 * @api DELETE /api/accounting-firms/{accountingFirmId}/vendors/{vendorId}/themes/{themeId}
 *
 * @param {Number} accountingFirmId
 * @param {Number} vendorId
 * @param {Number} themeId
 */
const destroy = (accountingFirmId, vendorId, themeId) => {
	const url = `accounting-firms/${accountingFirmId}/vendors/${vendorId}/themes/${themeId}`
	return axios.delete(url)
}

/**
 * @api GET /api/accounting-firms/{accountingFirmId}/vendors/{vendorId}/themes
 *
 * @param {Number} accountingFirmId
 * @param {Number} vendorId
 */
const get = (accountingFirmId, vendorId) => {
	const url = `accounting-firms/${accountingFirmId}/vendors/${vendorId}/themes`
	return axios.get(url)
}

/**
 * @api PATCH /api/accounting-firms/{accountingFirmId}/vendors/{vendorId}/themes/{themeId}
 *
 * @param {Number} accountingFirmId
 * @param {Number} vendorId
 * @param {Number} themeId
 * @param {Object} data
 */
const update = (accountingFirmId, vendorId, themeId, data) => {
	const url = `accounting-firms/${accountingFirmId}/vendors/${vendorId}/themes/${themeId}`
	return axios.patch(url, data)
}

/**
 * @api GET /api/accounting-firms/{accounting_firm_id}/themes
 *
 * @param {Number} accountingFirmId
 */
const getTeamManagerThemes = (accountingFirmId) => {
	const url = `accounting-firms/${accountingFirmId}/themes`
	return axios.get(url)
}

/**
 * @api GET /api/accounting-firms/{accounting_firm_id}/themes/users
 *
 * @param {Number} accountingFirmId
 * @param {Object} params
 * @param {Object} cancelToken
 */
const getTeamManagerUsers = (accountingFirmId, params, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel('Request cancelled')
	}
	const newCancelToken = Configurator.CancelToken.source()
	const url = `accounting-firms/${accountingFirmId}/themes/users`
	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			params: params
		})
	}
}

/**
 * @api POST /api/accounting-firms/{accounting_firm_id}/themes/users
 *
 * @param {Number} accountingFirmId
 * @param {Object} data
 * @param {Object} params
 */
const assignThemeToUsers = (accountingFirmId, data, params) => {
	const url = `accounting-firms/${accountingFirmId}/themes/users`
	return axios.post(url, data, { params: params })
}

/**
 * @api DELETE /api/accounting-firms/{accounting_firm_id}/themes/users
 *
 * @param {Number} accountingFirmId
 * @param {Object} data
 * @param {Object} params
 */
const unassignThemeToUsers = (accountingFirmId, data, params) => {
	const url = `accounting-firms/${accountingFirmId}/themes/users`
	return axios.delete(url, { data: data, params: params }, data)
}

/**
 * @api POST /api/accounting-firms/{accounting_firm_id}/users/{userId}/themes
 *
 * @param {Number} accountingFirmId
 * @param {Number} userId
 * @param {Object} data
 * @param {Object} params
 */
const assignThemesToUser = (accountingFirmId, userId, data, params) => {
	const url = `accounting-firms/${accountingFirmId}/users/${userId}/themes`
	return axios.post(url, data, { params: params })
}

/**
 * @api DELETE /api/accounting-firms/{accounting_firm_id}/users/{userId}/themes
 *
 * @param {Number} accountingFirmId
 * @param {Number} userId
 * @param {Object} data
 * @param {Object} params
 */
const unassignThemesToUser = (accountingFirmId, userId, data, params) => {
	const url = `accounting-firms/${accountingFirmId}/users/${userId}/themes`
	return axios.delete(url, { data: data, params: params }, data)
}

/**
 * @api GET /api/themes/acl
 */
const getACLList = () => {
	const url = `themes/acl`
	return axios.get(url)
}

export default {
	create: create,
	destroy: destroy,
	get: get,
	update: update,
	getTeamManagerThemes,
	getTeamManagerUsers: getTeamManagerUsers,
	assignThemeToUsers: assignThemeToUsers,
	unassignThemeToUsers: unassignThemeToUsers,
	assignThemesToUser: assignThemesToUser,
	unassignThemesToUser: unassignThemesToUser,
	getACLList: getACLList
}
