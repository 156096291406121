// ============================================================================
// ThemeService
// --------------
// Theme module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/ThemesApi'

import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

// ---------
// Internals
// ---------
const Private = {
	getState: function () {
		return store.state.themes.teamManager
	},
	getCancelToken: function (tokenName) {
		return Private.getState().cancelTokens[tokenName]
	},
	setCancelToken: function (cancelTokenValue) {
		return store.dispatch('themes/setTeamManagerCancelToken', cancelTokenValue)
	},
	setThemes: function (themes) {
		return store.dispatch('themes/setTeamManagerThemes', themes)
	},
	showSuccess: function (status) {
		let message
		if (status === 'all') {
			message = window.vueInstance.$t('themes.team-manager.assignment_in_progress')
		} else {
			message = window.vueInstance.$t('themes.team-manager.decommissioning_in_progress')
		}
		AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, message)
	},
	updateUserThemeStatus: function (user, themeTitle, status) {
		if (user.themes && user.themes[themeTitle] !== 'disabled') {
			user.themes[themeTitle] = status
		}
	},
	teamManager: {
		getUsers: function (paginationData) {
			const accountingFirmId = store.state.accountingFirm?.selected?.id
			const params = {
				page: paginationData.page,
				po: paginationData.rowsPerPage,
				t: Private.teamManager.store.getSearchedThemes()
			}
			if (Private.teamManager.store.getSearchedText()) {
				params.q = Private.teamManager.store.getSearchedText()
			}
			const callAnswer = API.getTeamManagerUsers(accountingFirmId, params, Private.getCancelToken('getUsers'))
			Private.setCancelToken({ cancelToken: callAnswer.cancelToken, item: 'getUsers' })
			return callAnswer.promise.then(res => res.data.data)
		},
		loadThemes: function () {
			API.getTeamManagerThemes(store.state.accountingFirm?.selected?.id)
				.then(res => res.data.data)
				.then(themes => {
					return Private.setThemes(themes)
				})
		},
		toggleThemeAssignment: function (themeTitle, status) {
			const accountingFirmId = store.state.accountingFirm?.selected?.id
			const data = {
				theme: themeTitle
			}
			const params = {}
			if (Private.teamManager.store.getSearchedText()) {
				params.q = Private.teamManager.store.getSearchedText()
			}
			if (status === 'all') {
				return API.assignThemeToUsers(accountingFirmId, data, params).then(() => {
					Private.showSuccess(status)
				})
			} else {
				return API.unassignThemeToUsers(accountingFirmId, data, params).then(() => {
					Private.showSuccess(status)
				})
			}
		},
		toggleUserThemeAssignment: function (user, themeTitle, status) {
			const accountingFirmId = store.state.accountingFirm?.selected?.id
			const themes = [themeTitle]
			const data = {
				themes: themes
			}
			const params = {}
			if (status === 'all') {
				return API.assignThemesToUser(accountingFirmId, user.id, data, params)
					.then(() => {
						Private.updateUserThemeStatus(user, themeTitle, status)
					})
					.then(() => {
						Private.showSuccess(status)
					})
			} else {
				return API.unassignThemesToUser(accountingFirmId, user.id, data, params)
					.then(() => {
						Private.updateUserThemeStatus(user, themeTitle, status)
					})
					.then(() => {
						Private.showSuccess(status)
					})
			}
		},
		toggleUserThemeAssignments: function (user, status) {
			const accountingFirmId = store.state.accountingFirm?.selected?.id
			const searchedThemes = Private.teamManager.store.getSearchedThemes()
			const data = {
				status: status,
				themes: searchedThemes
			}
			let params = {
			}
			let action
			if (status === 'all') {
				action = API.assignThemesToUser(accountingFirmId, user.id, data, params)
			} else {
				action = API.unassignThemesToUser(accountingFirmId, user.id, data, params)
			}
			return action
				.then(() => {
					if (user.themes) {
						Object.keys(user.themes).forEach(key => {
							if (searchedThemes.length === 0 || searchedThemes.includes(key)) {
								Private.updateUserThemeStatus(user, key, status)
							}
						})
					}
				})
				.then(() => {
					Private.showSuccess(status)
				})
		},
		rights: {
			canCheck: function () {
				const accountingFirm = store.state.accountingFirm?.selected
				return accountingFirm.isAccountantAdmin
			}
		},
		store: {
			getFilters: function () {
				return Private.getState().filters
			},
			getSearchedText: function () {
				return Private.teamManager.store.getFilters().search
			},
			getSearchedThemes: function () {
				return Private.teamManager.store.getFilters().themes
			},
			getThemes: function () {
				return Private.getState().themes
			},
			reset: function () {
				Private.setCancelToken({ cancelToken: null, item: 'getUsers' })
				Private.teamManager.store.setSearchedText(null)
				Private.teamManager.store.setSearchedThemes([])
				Private.setThemes([])
			},
			setSearchedText: function (searchedText) {
				let newFilters = { ...Private.teamManager.store.getFilters() }
				newFilters.search = searchedText
				return store.dispatch('themes/setTeamManagerFilters', newFilters)
			},
			setSearchedThemes: function (searchedThemes) {
				let newFilters = { ...Private.teamManager.store.getFilters() }
				newFilters.themes = searchedThemes
				return store.dispatch('themes/setTeamManagerFilters', newFilters)
			}
		}
	}
}

// -------
// Exports
// -------
export default {
	create: function (accountingFirmId, vendorId, data) {
		return API.create(accountingFirmId, vendorId, data).then(res => res.data.data)
	},
	destroy: function (accountingFirmId, vendorId, themeId) {
		return API.destroy(accountingFirmId, vendorId, themeId)
	},
	get: function (accountingFirmId, vendorId) {
		return API.get(accountingFirmId, vendorId).then(res => res.data.data)
	},
	update: function (accountingFirmId, vendorId, themeId, data) {
		return API.update(accountingFirmId, vendorId, themeId, data).then(res => res.data.data)
	},
	teamManager: Private.teamManager,
	getACLList: function () {
		return API.getACLList().then(res => res.data.data)
	}
}
