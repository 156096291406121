import { EventBus } from '@/events/EventBus'

export const Bus = new EventBus()

export const Events = Object.freeze({
	CREATE_NEW_MOBILE_APP: 'create-new-mobile-app',
	CANCEL_NEW_MOBILE_APP_CREATION: 'cancel-new-mobile-app-creation',
	SELECTED_MOBILE_APP: 'selected-mobile-app',
	CLEAR_SELECTED_MOBILE_APP: 'clear-selected-mobile-app',
	CREATE_MOBILE_APP: 'create-mobile-app',
	UPDATE_MOBILE_APP: 'update-mobile-app',
	DELETE_MOBILE_APP: 'delete-mobile-app',
	MOBILE_APP_CREATED: 'mobile-app-created',
	RESET_MOBILE_APP_LIST: 'reset-mobile-app-list',
	LOAD_MOBILE_APP_LIST: 'load-mobile-app-list',
	UPGRADE_MOBILE_APP: 'upgrade-mobile-app',
	UPGRADE_MOBILE_APPS: 'upgrade-mobile-apps',
	UPGRADE_AND_UPDATE_MOBILE_APP: 'upgrade-and-update-mobile-app',
})
